



















import Vue from 'vue';
import { IUUIDHelperService } from '../../../shared/src/services/IUUIDHelperService';

export default Vue.extend({
  inject: {
    uuidHelperService: 'uuidHelperService'
  },
  props: [
    'label',
    'value'],
  data() {
    return {
    key: '',
    locked: true,
    newValue: this.value
    };
  },
  methods: {
      valueUpdated(newValue: string): void {
        this.newValue = newValue;
      },
      toggleEdit(): void {
        if (!this.locked) {
          if (this.newValue !== this.value) {
            this.save();
          } else {
            this.cancel();
          }
        } else {
          this.enableEdit();
        }
      },
      save(): void {
          this.locked = true;
          this.$emit('updated', this.newValue);
          this.getTextArea().blur();
      },
      enableEdit(): void {
        this.locked = false;
        this.getTextArea().focus();
      },
      cancel(): void {
        this.locked = true;

        // Force text field to reload value
        this.key = ((this as any).uuidHelperService as IUUIDHelperService).getUUID();

        this.getTextArea().blur();
      },
      getTextArea(): HTMLElement {
        return this.$refs.textarea as HTMLElement;
      }
    }

});
