









































































import Vue from 'vue';
import TextField from '../components/TextField.vue';
import TextArea from '../components/TextArea.vue';
import { Category } from '../../../shared/src/entities/Category';
import ConfirmDialog from '../components/ConfirmDialog.vue';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';
import ImageUpload from '../components/ImageUpload.vue';
import { CategoryStatus } from '../../../shared/src/entities/CategoryStatus';
import ListSelectorField from '../components/ListSelectorField.vue';

export default Vue.extend({
  components: {
    TextField,
    ConfirmDialog,
    ImageUpload,
    ListSelectorField,
    TextArea
  },
  inject: {
    categoryDataservice: 'categoryDataservice',
    uuidHelperService: 'uuidHelperService',
    imageDataservice: 'imageDataservice'
  },
  data() {
    return {
      imageLoading: false as boolean
    };
  },
  computed: {
    categories(): Category[] {
      return ((this as any).categoryDataservice as ICategoryDataservice).categories;
    },
    categoryId(): string {
      return this.$route.params.id;
    },
    category(): Category | undefined {
      if (!this.$route.params.id) {
        return undefined;
      }
      return this.categories.find( (c) => c.id === this.$route.params.id);
    },
    breadcrumbs(): any[] {
        return [
          {
            text: 'Kategorier',
            disabled: false,
            to: {name: 'Categories'},
            exact: true
          },
          {
            text: this.categoryId,
            disabled: false,
            exact: true
          }
        ];
    },
    selectedStatus(): CategoryStatus {
      if (!this.category) {
          throw new Error('Failed getting category status, category was undefined, categoryId: ' + this.categoryId);
      }
      return this.category.status;
    },
    allCategoryStatusValues(): CategoryStatus[] {
      return CategoryStatus.all();
    },
  },
  methods: {
      categorynameUpdated(newName: string): void {
        if (!this.category) {
          throw new Error('Failed updating name, the category is undefined, categoryid:' + this.categoryId);
        }

        ((this as any).categoryDataservice as ICategoryDataservice).updateCategoryName(this.category.id, newName);
      },
      categorySortIndexUpdated(sortIndex: number): void {
        if (!this.category) {
          throw new Error('Failed updating sortIndex, the category is undefined, categoryid:' + this.categoryId);
        }

        ((this as any).categoryDataservice as ICategoryDataservice).updateCategorySortIndex(this.category.id, sortIndex);
      },
      imageUpdated(image: File): void {
        if (!this.category) {
          throw new Error('Failed updating image, the category is undefined, categoryid:' + this.categoryId);
        }

        if (!image) {
          throw new Error('Trying to set undefined image on categoryid: ' + this.categoryId);
        }

        this.imageLoading = true;
        ((this as any).categoryDataservice as ICategoryDataservice).updateCategoryImage(this.category.id, image).then(() => {
          this.imageLoading = false;
        });
      },
      imageDeleted(): void {
        if (!this.category) {
          throw new Error('Failed deleting image, the category is undefined, categoryid:' + this.categoryId);
        }

        if (!this.category.image) {
          throw new Error('Failed deleting image, the image is undefined, categoryid:' + this.categoryId);
        }

        ((this as any).categoryDataservice as ICategoryDataservice).deleteCategoryImage(this.category.id, this.category.image);
      },
      deleteCategory(): void {
        (this.$refs.confirmDialog as any).show('Er du sikker på at du vil slette kategorien?', this.acceptDeleteCategory);
      },
      acceptDeleteCategory(): void {
        if (!this.category) {
          throw new Error('Could not delete category as category is undefined, categoryid:' + this.$route.params.id);
        }
        ((this as any).categoryDataservice as ICategoryDataservice).deleteCategory(this.category.id).then(() => {
          this.$router.go(-1);
        });
      },
      statusUpdated(newStatus: CategoryStatus): void {
        if (!this.category) {
          return;
        }

        ((this as any).categoryDataservice as ICategoryDataservice).updateStatus(this.category.id, newStatus);
      },
      saveCategory(): void {
        if (!this.category) {
          return;
        }

        (this.$refs.nameComponent as any).save();
        (this.$refs.stateComponent as any).save();
        (this.$refs.sortComponent as any).save();
        (this.$refs.descriptionComponent as any).save();
        this.$router.go(-1);
      },
      descriptionUpdated(description: string): void {
        if (!this.category) {
          return;
        }

        ((this as any).categoryDataservice as ICategoryDataservice).setDescription(this.category.id, description);
      }
  }
});
