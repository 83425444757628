



























import Vue from 'vue';
import { IUUIDHelperService } from '../../../shared/src/services/IUUIDHelperService';

export default Vue.extend({
  inject: {
    uuidHelperService: 'uuidHelperService'
  },
  props: ['label', 'value', 'items'],
  data() {
    return {
      key: '' as string,
      locked: true as boolean,
      newValue: undefined
    };
  },
  methods: {
      valueUpdated(newValue: any) {
        this.newValue = newValue;
      },
      toggleEdit() {
        if (!this.locked) {
          this.save();
        } else {
          this.enableEdit();
        }
      },
      save() {
          this.locked = true;
          this.$emit('updated', this.newValue);
          this.getAutocomplete().blur();
      },
      enableEdit() {
          this.locked = false;
          this.getAutocomplete().focus();
      },
      cancel() {
        this.locked = true;

        // Force text field to reload value
        this.key = ((this as any).uuidHelperService as IUUIDHelperService).getUUID();

        this.getAutocomplete().blur();
      },
      getAutocomplete(): HTMLElement {
        return this.$refs.autocomplete as HTMLElement;
      }
    }
});
