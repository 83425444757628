































import Vue from 'vue';

export default Vue.extend({
  props: [
    'imageUrl',
    'imageLoading',
    'uploading'],
  methods: {
      imageUpdated(files: File[]) {
        const file = files[0];
        if (file) {
          this.$emit('imageUpdated', file);
        } else {
          throw new Error('Image to upload not found, files: ' + files);
        }
      },
      deleteImage() {
        this.$emit('imageDeleted');
      }
    }
});
